<template>
  <div class="page-content">
    <KlubbaRichText class="texts">
      <p><b>You are welcome back any time.</b></p>
      <p>
        Subscription
        <b>{{ club.name }} - {{ selectedClubSubscription.name }}</b>
        was successfully removed from your Klubba account.
      </p>
      <p>Please use the link below if you want to choose another subscription.</p>
    </KlubbaRichText>

    <div class="button-wrap">
      <klubba-button
        @click="goToAddSubscriptionPage"
        buttonText='Choose subscription'
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import KlubbaButton from '../../components/default/KlubbaButton.vue';

export default {
  name: 'SubscriptionRemoved',
  components: { KlubbaButton, KlubbaRichText },
  computed: {
    ...mapGetters(['club', 'selectedClubSubscription']),
  },
  methods: {
    goToAddSubscriptionPage() {
      this.$router.push(`/${this.club.id}/product-details`);
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8 pb-0

.button-wrap
  @apply p-8 mt-auto
</style>
